import React, { Component } from 'react';
import { Header, Icon, Loader, Segment, Tab } from 'semantic-ui-react';
import { Flip, toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import AppDetailsModal from './AppDetailsModal';
import { calculateHash, editPackageName, getPrivileges, onChangeFileInput } from '../../utils/Methods';
import { showError, showInfo, showSuccess } from '../../utils/ToastHelpers';
import AppUploadModal from '../../components/AppUploadModal';
import FileUploadModal from '../../components/FileUploadModal';
import FileEditModal from '../../components/FileEditModal';
import {
  abortSession,
  checkAsyncTaskStatus,
  getAgentListSummary,
  getAppCategoryList,
  getAppList,
  getFileList,
  uploadAppV3,
  uploadChunk,
  uploadFileV2
} from '../../api/apiCalls';
import LibraryAppList from '../../components/LibraryAppList';
import LibraryFileList from '../../components/LibraryFileList';
import LibraryAppUpload from '../../components/LibraryAppUpload';
import FileDetailsModal from '../../components/FileDetailsModal';
import LibraryFileUpload from '../../components/LibraryFileUpload';
import { PrivilegeConstants } from '../../utils/PrivilegeConstants';
import {
  ACCEPTABLE_APP_EXTENSION,
  ACCEPTABLE_FILE_EXTENSION,
  ANDROID_APP_EXTENSION,
  ASYNC_TASK_STATUS,
  DEVICEOS,
  HOOK_NAMES
} from '../../utils/Constants';
import FarmGenericPagination from '../../components/FarmGenericPagination';
import {
  APPLICATION_HEADER,
  CATEGORY_LABEL,
  FAILED_ERROR_MESSAGE,
  FILE_HEADER,
  LIBRARY_HEADER,
  libraryMessages,
  LOADING,
  NAME_HEADER,
  NOT_FETCH_APPS,
  NOTHING_HERE_MESSAGE,
  SUCCESS_MESSAGE,
  UPLOAD_NEW_FILE_LABEL,
  VERSION_HEADER
} from '../../utils/UIMessages';
import { FOLDER_OPEN_OUTLINE_ICON } from '../../utils/UiIcons';
import NewAnnouncementFormModal from '../announcements/NewAnnouncementFormModal';
import fileExtension from 'file-extension';
import axios from 'axios';

let controller;
class Library extends Component {
    state = {
        appList: {},
        appFile: undefined,
        loading: false,
        appUploadModalVisibility: false,
        fileUploadModalVisibility: false,

        uploadAppPercent: 0,
        uploadFilePercent: 0,
        detailAppModalVisibility: false,
        detailFileModalVisibility: false,
        selectedApp: [],
        selectedFile: [],
        allAppCategories: [],
        appVersionName: '',
        appCategory: '',
        searchKeyword: '',
        apps: [],
        files: [],
        privileges: [],
        loadingAppList: false,
        resign: false,
        resignServerAvailable: false,
        activeIndex: 0,
        loadingFileList: false,
        fileList: {},
        name: '',
        loadingFileUpload: false,
        fileData: undefined,
        deleteFile: false,
        fileEditModalVisibility: false,
        editFileId: null,
        searchFileKeyword: '',
        fileExtensionErrorMsg: '',
        selectedFileOs: '',
        isSimulate: false,
        simulateHardwareTaskId: null,
        uploadSessionToken: '',

        //Upload App and Announce It
        isAnnounceAfterAppUpload: false,
        openAnnouncementFormModal: false,
        fileNameForAnnouncement: null,
        appVersionNameForAnnouncement: null,
        appCategoryForAnnouncement: null,

        //File Library Pagination Settings
        totalPagesFiles: 0,
        pageIndexFiles: 0,
        totalNumOfFiles: 0,
        totalNumOfApps: 0
    };
    panes = () => [
        this.state.privileges?.includes(PrivilegeConstants.APPLICATION_MANAGEMENT.LIBRARY_ACCESS) &&
        {
            menuItem: APPLICATION_HEADER(),
            render: () => <>
                <div>
                    <LibraryAppUpload
                        totalNumOfApps = {this.state.totalNumOfApps}
                        handleAppSearch={this.handleAppSearch}
                        searchKeyword={this.state.searchKeyword}
                        privileges={this.state.privileges}
                        handleOpenAppUploadModal={this.handleOpenAppUploadModal}
                    />
                </div>
            </>
        },
        this.state.privileges?.includes(PrivilegeConstants.FILE_MANAGEMENT.LIST) &&
        {
            menuItem: FILE_HEADER(),
            render: () => <>
                <div>
                    <LibraryFileUpload
                        totalNumOfFiles={this.state.totalNumOfFiles}
                        handleFileSearch={this.handleFileSearch}
                        searchFileKeyword={this.state.searchFileKeyword}
                        privileges={this.state.privileges}
                        handleOpenFileUploadModal={() => this.setState({ fileUploadModalVisibility: true })}
                    />
                </div>
            </>
        }
    ]

    handleOpenAppUploadModal = () => this.setState({
        fileNameForAnnouncement: null,
        appCategoryForAnnouncement: null,
        appVersionNameForAnnouncement: null,
        isAnnounceAfterAppUpload:false
    }, () => this.setState({ appUploadModalVisibility: true }));

    handleCloseUploadAppModal = () => {
        this.setState({
            appUploadModalVisibility: false,
            appFile: undefined,
            loading: false,
            uploadAppPercent: 0,
            appVersionName: '',
            appCategory: '',
            resign: false,
            fileExtensionErrorMsg: '',
            selectedFileOS: ''
        });
        if (this.state.loading) {
            controller.abort('Canceled by user');
            const token = this.state.uploadSessionToken;
            if(token){
                abortSession(token).catch(e => {
                    showError(`${FAILED_ERROR_MESSAGE()}: 
                    ${libraryMessages(e.response?.data?.message ?? e.toString()).ERROR_MESSAGE}`);
                })
            }
        }
    };

    handleCancelUpload = () => {
        this.handleCloseUploadAppModal()
    }

    handleCloseFileUploadModal = () => {
        this.setState({
            fileUploadModalVisibility: false,
            loadingFileUpload: false,
            appFile: undefined,
            name: '',
            fileExtensionErrorMsg: ''
        })
    }

    handleCloseFileEditModal = () => {
        this.setState({ fileEditModalVisibility: false })
    }

    getApps = () => {
        this.setState({ loadingAppList: true });
        if (this.state.privileges.includes(PrivilegeConstants.APPLICATION_MANAGEMENT.LIBRARY_ACCESS)) {
            getAppList().then(response => {
                if (response.data) {
                    const allApps = editPackageName(response.data);
                    const groupedByPackage = this.groupBy(allApps, 'appPackage');
                    this.setState({
                        appList: groupedByPackage,
                        apps: response.data,
                        totalNumOfApps: Object.keys(groupedByPackage).length
                    });
                }
            })
                .catch(err => {
                    showError(
                        `${NOT_FETCH_APPS()} ${err?.response?.data?.message ?? err.toString()
                        }`
                    )})
                .finally(() => this.setState({ loadingAppList: false }));
        } else {
            this.setState({ loadingAppList: false });
        }
    }

    getFileList = async () => {
        this.setState({ loadingFileList: true });
        if (this.state.privileges.includes(PrivilegeConstants.FILE_MANAGEMENT.LIST)) {
            try {
                const res = await getFileList({ page: this.state.pageIndexFiles });
                const groupedByFileName = this.groupBy(res.data.content, 'fileName');
                this.setState({
                    fileList: groupedByFileName,
                    files: res.data.content,
                    totalPagesFiles: res.data.totalPages,
                    pageIndexFiles: res.data.pageable.pageNumber,
                    totalNumOfFiles: res.data.totalElements
                })
            } catch (err) {
                showError(`${FAILED_ERROR_MESSAGE()} ${err?.response?.data?.message ?? err.toString()}`)
            } finally {
                this.setState({ loadingFileList: false })
            }
        } else {
            this.setState({ loadingFileList: false });
        }
    }

    getAppCategories = () => {
        if (this.state.privileges.includes(PrivilegeConstants.APPLICATION_MANAGEMENT.LIBRARY_ACCESS)) {
            getAppCategoryList().then(res => {
                let allAppCategories = res.data
                    .filter(category1 => category1 !== 'Uncategorized')
                    .map(category => {
                        return {
                            key: category,
                            value: category,
                            text: category
                        };
                    });
                this.setState({ allAppCategories });
            })
            .catch(err => {
                showError(`${libraryMessages().NOT_FETCH_APP_CATEGORIES} ${err?.response?.data?.message ?? err.toString()}`);
            });
        }
    };


    checkResignServerAvailability = () => {
        getAgentListSummary().then(res => {
            this.setState(
                { 'resignServerAvailable': res.data.find(({ type }) => type === 'RESIGN_SERVER') !== undefined }
            )
        })
    }

    handleAddition = (e, { value }) => {
        this.setState(prevState => ({
            allAppCategories: [
                { text: value, value, key: value },
                ...prevState.allAppCategories
            ]
        }));
    };

    onChangeAnnounceNewApp = () => {
        this.setState({isAnnounceAfterAppUpload:!this.state.isAnnounceAfterAppUpload});
    }

    calculatePercent = (fileSize, processedBytes) => {
        const result =  Math.floor(100*processedBytes/fileSize);
        return result;
    }

    chunkUpload = async (token,chunk) => {
        controller = new AbortController();
        const formData = new FormData();
        formData.append('token',token);
        formData.append('file', chunk);
        try{
          return await uploadChunk(formData, { signal: controller.signal });
        } catch (e) {
            if(axios.isCancel(e)){
              showError(`${libraryMessages().REQUEST_CANCELED_ERROR} ${e}`);
            }
          showError(libraryMessages().ERROR_UPLOAD_CHUNK);
        }

    }

    generateFileUploadInfo = async (appFile) => {
        const originalFileName = appFile.name;
        const fileSize = appFile.size
        const hash = await calculateHash(appFile);
        return {
            originalFileName: originalFileName,
            fileSize: fileSize,
            hash: hash
        }
    }

    handleUploadApp = async () => {

        showInfo(libraryMessages().SHOW_PREPARE_FILE_UPLOAD,1000);
        this.setState({ loading: true });

        const appFile = this.state.appFile.get('file');

        const fileInfo = await this.generateFileUploadInfo(appFile);
        if(!this.state.loading){
          return;
        }
        const fileSize = fileInfo.fileSize;

        const body =  {
            'resign': this.state.resign,
            'simulateHardware': this.state.isSimulate,
            ...fileInfo
        };

        if(this.state.appCategory !== ''){
            body.appCategory = this.state.appCategory;
        }
        if(this.state.appVersionName !== ''){
            body.appVersionName = this.state.appVersionName;
        }

        const response = await uploadAppV3(body);
        const {token, chunkSize} = response.data;
        this.setState({ uploadSessionToken: token });
        let start = 0;
        showInfo(libraryMessages().SHOW_START_FILE_UPLOAD, 5000);
        let simulateHardwareTaskId;
        while (start < fileSize) {
            const chunk = appFile.slice(start, start + chunkSize);
            const sentBytes = chunk.size;

            try {
                const chunkResp = await this.chunkUpload(token, chunk);
                if(!chunkResp){
                  break;
                }
              const {receivedBytes,mergeTaskId} = chunkResp.data;
              if(receivedBytes !== sentBytes) {
                    showError(libraryMessages().SHOW_ERROR_FILE_UPLOAD);
                    break;
                }

                if(mergeTaskId) {
                    this.checkAsyncTask(mergeTaskId, libraryMessages().SHOW_SUCCESS_FILE_UPLOAD,
                      async (resp)=>  {
                          await this.getApps();
                          const hookResponses = resp.hookResponses.find(i => i.name === HOOK_NAMES.APP_UPLOAD);
                          simulateHardwareTaskId = hookResponses.result[0]?.simulateHardwareTaskId;
                          if(simulateHardwareTaskId){
                              this.setState({injectTaskId: simulateHardwareTaskId});
                              showInfo(libraryMessages().START_INJECTION_TASK,5000);
                              this.checkAsyncTask(simulateHardwareTaskId,libraryMessages().START_INJECTION_TASK,
                                () => this.setState({injectTaskId:null}), libraryMessages().ERROR_INJECTION_TASK);
                          }

                      },
                      libraryMessages().SHOW_ERROR_FILE_UPLOAD);
                }

            }catch (e) {
                showError(libraryMessages().REQUEST_CANCELED_ERROR);
                break;
            }


            this.setState({ loadingFileUpload: false });
            start += chunkSize;
            this.setState({uploadAppPercent: this.calculatePercent(fileSize, start)});
            if(start === fileSize){
                this.setState({ loading: false, appUploadModalVisibility: false });
                toast.success(SUCCESS_MESSAGE(), {
                    autoClose: 3000,
                    transition: Flip
                });
            }
        }
        this.handleCloseUploadAppModal();
        this.state.isAnnounceAfterAppUpload && this.setState({openAnnouncementFormModal:true});
        this.setState({loading: false, isSimulate: false});
    };

    handleUploadFile = async () => {
        showInfo(libraryMessages().SHOW_PREPARE_FILE_UPLOAD,5000);
        this.setState({ loadingFileUpload: true });

        const appFile = this.state.appFile.get('file');
        const fileInfo = {name : this.state.name,
            ...await this.generateFileUploadInfo(appFile)};
        const fileSize = fileInfo.fileSize;

        const response = await uploadFileV2(fileInfo);
        const {token, chunkSize} = response.data;
        let start = 0;
        showInfo(libraryMessages().SHOW_START_FILE_UPLOAD, 1000);
        while (start < fileSize) {

            const chunk = appFile.slice(start, start + chunkSize);
            const sentBytes = chunk.size;
            const chunkResp = await this.chunkUpload(token, chunk);
            if(!chunkResp){
                break;
            }
            const {receivedBytes,mergeTaskId} = chunkResp.data;

            if(receivedBytes !== sentBytes) {
                showError(libraryMessages().SHOW_ERROR_FILE_UPLOAD);
                break;
            }
            if(mergeTaskId) {
                this.checkAsyncTask(mergeTaskId, libraryMessages().SHOW_SUCCESS_FILE_UPLOAD,
                  async ()=>  await this.getFileList(),
                  libraryMessages().SHOW_ERROR_FILE_UPLOAD);
            }
            this.setState({ loadingFileUpload: false });
            start += chunkSize;
            this.setState({uploadFilePercent:
                  this.calculatePercent(fileSize, start)});
        }
        this.setState({ name: '', appFile: null, loadingFileUpload: false });
        this.setState({fileUploadModalVisibility: false, uploadFilePercent: 0});

    }

    onChangeAppUpload = e => {
        onChangeFileInput(e.target.files[0], ACCEPTABLE_APP_EXTENSION,
            fileExtensionErrorMsg => this.setState({ fileExtensionErrorMsg }),
            appFile => this.setState({ appFile }),
            fileName => this.setState({ fileNameForAnnouncement: fileName }));

        const file = e.target.files[0];
        if(file){
            const extension = fileExtension(file.name);
            if(ANDROID_APP_EXTENSION.includes(extension)){
                this.setState({selectedFileOS:DEVICEOS.ANDROID});
            }else{
              this.setState({selectedFileOS:DEVICEOS.IOS});
            }
        }
    }

    onChangeFileUpload = e => {
        onChangeFileInput(e.target.files[0], ACCEPTABLE_FILE_EXTENSION,
            fileExtensionErrorMsg => this.setState({ fileExtensionErrorMsg }),
            appFile => this.setState({ appFile }))
    };

    handleAppSearch = (e, { value }) => {
        const { apps } = this.state;
        value = value.toLowerCase();
        const filterApp = apps.filter(app => {
            return (
                app.appName?.toLowerCase().includes(value) ||
                app.appVersion?.toLowerCase().includes(value) ||
                app.appVersionCode?.toLowerCase().includes(value) ||
                app.appVersionName?.toLowerCase().includes(value) ||
                app.appCategory?.toLowerCase().includes(value) ||
                app.appOs?.toLowerCase().includes(value)
            );
        });
        const appList = this.groupBy(filterApp, 'appPackage');
        this.setState({ appList, searchKeyword: value });
    };

    handleFileSearch = (e, { value }) => {
        const { files } = this.state;
        value = value.toLowerCase();
        const filterFile = files.filter(file => {
            return (
                file.fileName?.toLowerCase().includes(value) ||
                file.name?.toLowerCase().includes(value)
            );
        })
        const fileList = this.groupBy(filterFile, 'fileName');
        this.setState({ fileList, searchFileKeyword: value });
    }

    groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    checkAsyncTask = (taskId, successMsg, doItIfSuccess, errMsg) => {

        const intervalId= setInterval(()=> {
            checkAsyncTaskStatus(taskId).then(res => {
                const status = res.data?.status;
                if(status === ASYNC_TASK_STATUS.COMPLETED){
                    showSuccess(successMsg,5000);
                    if(doItIfSuccess){
                      doItIfSuccess(res.data);
                    }
                    clearInterval(intervalId);
                }
                else if(status === ASYNC_TASK_STATUS.ERROR){
                    showError(errMsg);
                    clearInterval(intervalId);
                }
            }).catch(() => {
                showError(errMsg);
                clearInterval(intervalId)
            });
        },10000);
    }


    componentDidUpdate(prevProps, prevState) {
        const { appUploadModalVisibility, detailFileModalVisibility, searchFileKeyword } = this.state;
        if (appUploadModalVisibility !== prevState.appUploadModalVisibility && appUploadModalVisibility) {
            this.getAppCategories();
            this.checkResignServerAvailability();
        }

        if ((detailFileModalVisibility !== prevState.detailFileModalVisibility && !detailFileModalVisibility && !searchFileKeyword)) {
            this.getFileList();
        }
        const simulateHardwareTaskId = this.state.simulateHardwareTaskId;
        if(simulateHardwareTaskId !== prevState.simulateHardwareTaskId && simulateHardwareTaskId != null){
            this.checkAsyncTask(simulateHardwareTaskId,libraryMessages().READY_INJECTION_TASK,null,
              libraryMessages().ERROR_INJECTION_TASK);
        }

    }

    componentDidMount() {
        const privileges = getPrivileges();
        this.setState({ privileges }, () => {
            this.getApps();
            this.getAppCategories();
            if (!this.state.privileges?.includes(PrivilegeConstants.APPLICATION_MANAGEMENT.LIBRARY_ACCESS)) {
                this.setState({ activeIndex: 1 })
            }
            this.getFileList();
        });
    }

    //File Library Pagination----------------------------------
    onPageChangeFiles = activePage => {
        this.setState({ pageIndexFiles: activePage }, () => this.getFileList());
    }

    announceMessageGenerator = () => {
        const res= {
            NAME:libraryMessages().APP_UPLOAD_ANNOUNCEMENT_DESCRIPTION_MSG,
            DESC:
            `${NAME_HEADER()}: ${this.state.fileNameForAnnouncement ?? 'Not Given'}
            \n${VERSION_HEADER()}: ${this.state.appVersionNameForAnnouncement ?? 'Not Given'}
            \n${CATEGORY_LABEL()}: ${this.state.appCategoryForAnnouncement ?? 'Not Given'}`
        }
        return res;
    }

    render() {
        const { appList, fileList, activeIndex } = this.state;
        return (
            <>
                <div className='main-container'>
                    <div className='main-left-container'>
                        <h1> {LIBRARY_HEADER()}</h1>

                        <div className='main-left-content'>
                            <Tab
                                panes={this.panes()}
                                menu={{ secondary: true, pointing: true }}
                                onTabChange={(e, { activeIndex }) => {
                                    if (activeIndex === 0) {
                                        this.setState({ searchFileKeyword: '' })
                                        this.getApps();
                                    } else {
                                        this.setState({ searchKeyword: '' })
                                        this.getFileList();
                                    }
                                    this.setState({ activeIndex })
                                }}
                                activeIndex={this.state.activeIndex}
                            />
                        </div>
                    </div>
                    <div className='main-right-container'>
                        {
                            activeIndex === 0 ?
                                (!_.isEmpty(appList) ?
                                    <LibraryAppList
                                        appList={appList}
                                        onOpenAppDetail={selectedApp => this.setState({ detailAppModalVisibility: true, selectedApp })}
                                    />
                                    : this.state.loadingAppList ?
                                        (<Loader
                                            active={this.state.loadingAppList}
                                            inline={'centered'}
                                            indeterminate
                                        >
                                            {' '}
                                            {LOADING()}{' '}
                                        </Loader>)
                                        :
                                        (<Segment placeholder attached={'top'}>
                                            <Header icon>
                                                <Icon name={FOLDER_OPEN_OUTLINE_ICON} />
                                                {NOTHING_HERE_MESSAGE()}
                                            </Header>
                                        </Segment>)
                                )
                                :
                                (!_.isEmpty(fileList) ? (
                                    <div>
                                        <LibraryFileList
                                            fileList={fileList}
                                            onOpenFileDetail={selectedFile => this.setState({ detailFileModalVisibility: true, selectedFile })}
                                        />
                                        {!this.state.searchFileKeyword.trim() &&
                                            <FarmGenericPagination
                                                activePageProp={this.state.pageIndexFiles + 1}
                                                pageIndexProp={this.state.pageIndexFiles}
                                                totalPagesProp={this.state.totalPagesFiles}
                                                onPageChangeProp={this.onPageChangeFiles}
                                                //Specific CSS Customization
                                                classNameProp='library-pagination'
                                            />
                                        }
                                    </div>) :
                                    this.state.loadingFileList ?
                                        (<Loader
                                            active={this.state.loadingFileList}
                                            inline={'centered'}
                                            indeterminate
                                        >
                                            {' '}
                                            {LOADING()}{' '}
                                        </Loader>) :
                                        (<Segment placeholder attached={'top'}>
                                            <Header icon>
                                                <Icon name={FOLDER_OPEN_OUTLINE_ICON} />
                                                {NOTHING_HERE_MESSAGE()}
                                            </Header>
                                        </Segment>)
                                )
                        }
                    </div>
                </div>

                {/*AppUploadModal Modal*/}
                <AppUploadModal
                    appUploadModalVisibility={this.state.appUploadModalVisibility}
                    handleCloseUploadAppModal={this.handleCloseUploadAppModal}
                    handleCancelUpload={this.handleCancelUpload}
                    selectAppCategory={selectedAppCategory => this.setState({ appCategory: selectedAppCategory, appCategoryForAnnouncement: selectedAppCategory })}
                    allAppCategories={this.state.allAppCategories}
                    handleAddition={this.handleAddition}
                    value={this.state.appCategory}
                    setAppVersionName={value => this.setState({ appVersionName: value, appVersionNameForAnnouncement: value })}
                    appVersionName={this.state.appVersionName}
                    onChangeAppUpload={this.onChangeAppUpload}
                    resignServerAvailable={this.state.resignServerAvailable}
                    resign={this.state.resign}
                    onChangeResign={() => this.setState(prevState => ({ ...prevState, resign: !prevState.resign }))}
                    uploadAppPercent={this.state.uploadAppPercent}
                    appFile={this.state.appFile}
                    loadingUploadButton={this.state.loading}
                    handleUploadApp={this.handleUploadApp}
                    fileExtensionErrorMsg={this.state.fileExtensionErrorMsg}
                    selectedFileOS={this.state.selectedFileOS}
                    onChangeInjection={() => this.setState(prevState => ({ ...prevState, isSimulate: !prevState.isSimulate }))}

                    privilegesProp={this.state.privileges}
                    isAnnounceAfterAppUploadProp = {this.state.isAnnounceAfterAppUpload}
                    onChangeAnnounceNewAppProp={this.onChangeAnnounceNewApp}
                />

                {/* Announcement Registration From---------------------------------------------- */}
                {
                    this.state.openAnnouncementFormModal && <NewAnnouncementFormModal
                        openModalProp={this.state.openAnnouncementFormModal}
                        setOpenModalFuncProp={(option) => this.setState({openAnnouncementFormModal:option})}
                        defaultAnnouncementNameProp={this.announceMessageGenerator().NAME}
                        defaultAnnouncementDescriptionProp={this.announceMessageGenerator().DESC}
                    />
                }
                <AppDetailsModal
                    selectedApp={this.state.selectedApp}
                    callback={this.getApps}
                    detailModalVisibility={this.state.detailAppModalVisibility}
                    handleCloseDetailModal={() => this.setState({ detailAppModalVisibility: false })}
                />

                {/*FileUploadModal Modal*/}
                <FileUploadModal
                    fileUploadModalVisibility={this.state.fileUploadModalVisibility}
                    handleCloseFileUploadModal={this.handleCloseFileUploadModal}
                    name={this.state.name}
                    onChangeName={value=>this.setState({name:value})}
                    onChangeFileUpload={this.onChangeFileUpload}
                    handleUploadFile ={this.handleUploadFile}
                    loadingUploadButton={this.state.loadingFileUpload}
                    openEditModal={()=>this.setState()}
                    uploadFile = {this.state.appFile}
                    fileExtensionErrorMsg = {this.state.fileExtensionErrorMsg}
                    header = {UPLOAD_NEW_FILE_LABEL()}
                    isContainFileName = {true}
                    acceptableExtensions={ACCEPTABLE_FILE_EXTENSION}
                    description={null}
                    appFile={this.state.appFile}
                    fileUploadPercent={this.state.uploadFilePercent}
                />

                <FileDetailsModal
                    selectedFile={[this.state.selectedFile]}
                    detailModalVisibility={this.state.detailFileModalVisibility}
                    onClose={() => this.setState({ detailFileModalVisibility: false, selectedFile: [] })}
                    deleteFile={deleteFile => this.setState({ deleteFile })}
                    searchFileKeyword={searchFileKeyword => this.setState({ searchFileKeyword })}
                    openFileEditModal={(name, editFileId) => this.setState({ fileEditModalVisibility: true, name, editFileId })}
                />

                <FileEditModal
                    editModalVisibility={this.state.fileEditModalVisibility}
                    onClose={() => this.setState({ fileEditModalVisibility: false, searchFileKeyword: '', name: '' })}
                    name={this.state.name}
                    id={this.state.editFileId}
                    getFileList={() => this.getFileList()}
                />
            </>
        );
    }
}

export default withTranslation()(Library);


