import React, {Component, Suspense} from 'react';
import FilterBar from '../../components/FilterBar';
import axios from 'axios';
import { Button, Dimmer, Icon, Label, Loader, } from 'semantic-ui-react';
import { DEBOUNCE_WAIT } from '../../utils/Constants';
import { withTranslation } from 'react-i18next';
import { filterByValue, getPrivileges, iconSelector, orderDevices, stringSelector, valueSelector, } from '../../utils/Methods';
import { getDataForCache } from '../../utils/CacheStorageHandler';
import { Base64 } from 'js-base64';
import { connect } from 'react-redux';
import { endDeviceLoad, startDeviceLoad, setDeviceList, setDeviceListFilter } from '../../actions';
import { showError, showInfo } from '../../utils/ToastHelpers';
import DeviceSidebar from '../../components/DeviceSidebar';
import CancelReservationModal from '../../components/CancelReservationModal';
import {
    deleteFavoriteDevice,
    createFavoriteDevice,
    getReservableGroupList,
    getSystemParameters
} from '../../api/apiCalls'
import qs from 'query-string';
import Chat from '../../components/chat/Chat';
import { ADD_DEVICE_TO_FAVORITE_MESSAGE, APPLICATION_HEADER, APP_VERSION_HEADER, deviceListPageMessages, 
    DEVICE_LIST_HEADER, LOADING, NOT_FETCH_DEVICE_LIST, REMOVE_DEVICE_FROM_FAVORITE_MESSAGE, 
    SEARCH_PLACEHOLDER,NOT_FETCH_SYSTEM_PARAMETERS_ERROR_MESSAGE } from '../../utils/UIMessages';
import { IMAGE_ICON, MOBILE_ALTERNATE_ICON, STAR_ICON, TH_LIST_ICON, X_ICON } from '../../utils/UiIcons';
import DebouncedInput from '../../components/ReactTableV8/DebouncedInput';
import { PrivilegeConstants } from '../../utils/PrivilegeConstants'


const DeviceListCardPromise = import("./DeviceListCard");
const DeviceListTablePromise = import("./DeviceListTable");
const DeviceListCard = React.lazy(() => DeviceListCardPromise);
const DeviceListTable = React.lazy(() => DeviceListTablePromise);

class DeviceListPage extends Component {
    state = {
        //orderDevicesBy: 0, //For later usage. 0: default(brand and model asc), 1: default(brand and model desc), 2: udid, 3: status
        deviceListClone: [],
        deviceListForTable: [],
        selectedFilterValues: {},
        searchKeyword: "",
        selectedFilterAppValues: "",
        selectedFilterAppVersion: '',
        cardView: true,
        toggleSwitch: true,
        userDetails: "",
        privileges: [],
        loadingImages: true,
        loading: false,
        isGenerateFilterBar: true,
        selectedDeviceIdList: [],
        isSidebarVisible: false,
        isOpenCancelReservationModal: false,
        cancelledReservationId: null,
        reservableGroupList:[],
        chatPortalVisibility:false,
        systemParameters:[],
        nodeList: [],
        groupList: []
    };

    toggleSelectedDevice = (deviceId, checked) => {
        if (checked) {
            this.setState((prev) => {
                if (this.state.selectedDeviceIdList.length < 3) {
                    return {
                        ...prev,
                        selectedDeviceIdList:
                            prev.selectedDeviceIdList.concat(deviceId),
                    };
                } else {
                    return prev;
                }
            });
        } else {
            this.setState((prev) => ({
                ...prev,
                selectedDeviceIdList: prev.selectedDeviceIdList.filter(
                    (id) => id !== deviceId
                ),
            }));
        }
    };

    connectToDevices = () => {
        if (this.state.selectedDeviceIdList.length > 0) {
            let queryStr = "?";
            this.state.selectedDeviceIdList.forEach(
                (deviceId, idx) =>
                    (queryStr += `device_${idx + 1}=${deviceId}&`)
            );
            this.props.history.push({
                pathname: "/device-manage",
                search: queryStr,
            });
        }
    };

    getDevices = () => {
        this.props.startDeviceLoad("");
        axios
            .get("/api/devices")
            .then((res) => {
                this.props.endDeviceLoad();
                res.data = orderDevices(res.data);
                const nodeList = [];
                const groupList = [];
                res.data?.forEach((device)=>{
                    device.groups?.forEach(group => {
                      if(!groupList.some(slv => slv.id === group?.id)){
                        groupList.push(group)
                    }})
                    if(!nodeList.some(slv => slv.id === device?.node?.id)){
                        nodeList.push(device?.node)
                    }
                 })
                const updatedDeviceList = res.data.map((device)=>{
                    device.visible = this.checkVisibilityOfDevice(device);
                    return device;
                });
                this.setState(
                    {
                        deviceListClone: updatedDeviceList,
                        deviceListForTable: updatedDeviceList,
                        isGenerateFilterBar: true,
                        nodeList,
                        groupList
                    }
                );
                this.props.setDeviceList(updatedDeviceList);
                this.props.setDeviceListFilter(updatedDeviceList);
            })
            .catch((err) => {
                this.props.endDeviceLoad();
                showError(
                    `${NOT_FETCH_DEVICE_LIST()} ${err?.response?.status}`
                );
            });
    };

    closeFilter = (values, key) => {
        const currentUrl = window.sessionStorage.deviceSearchUrl;
        let result;
        values[key] = [];
        if (key === 'Reserved') {
            values['reservedByMe'] = [];
            result = currentUrl.replace(`${currentUrl}`, '');
        } else {
            const params = new URLSearchParams(currentUrl);
            params.delete(key);
            result = params.toString();
        }
        this.props.history.replace({ search: `${result}` });
        window.sessionStorage.removeItem('deviceSearchUrl');
        this.setState({ deviceListClone: [], deviceListForTable: [] }, () => {
            this.getDevices();
        });
    }

    closeAppFilter = () => {
        const currentUrl =  window.sessionStorage.deviceSearchUrl;
        const result = currentUrl.replace(/packageName=.*?&/, '');
        this.props.history.replace({search:`${result}`});
        window.sessionStorage.removeItem('deviceSearchUrl');
        this.setState({ deviceListClone: [], deviceListForTable: [], selectedFilterAppValues: '' }, () => {
            this.getDevices();
        });
    }

    getFilterText = (key) => {
        if(key === "groupList" || key === "nodeList"){
            return this.state.selectedFilterValues[key].map(item => this.state[key]?.find(value => value.id === item)?.name)?.toString() 
        }
        return valueSelector(this.state.selectedFilterValues[key])
    }
    showFilterValues = () => {
        const { selectedFilterValues, selectedFilterAppValues, selectedFilterAppVersion } = this.state;
        return <>
          {
            Object.keys(selectedFilterValues).map(key => {
                if (selectedFilterValues[key].length !== 0) {
                    return (
                        <div key={key}>
                            <Label id='label-main-filter' key={key}>
                                <Icon name={iconSelector(key)} />
                                 {stringSelector(key)}
                                <Label.Detail>
                                {this.getFilterText(key)}
                                </Label.Detail>
                                <Icon name={'close'} style={{marginLeft: 5}} onClick={() => this.closeFilter(selectedFilterValues, key)}/>
                            </Label>
                        </div>
                    );
                } else {
                  return null;
                }
            })
          }
          {
            selectedFilterAppValues &&
              <div key='application'>
                <Label id='label-main-filter' key='application'>
                    <Icon name={iconSelector('application')} />
                      {APPLICATION_HEADER()}
                    <Label.Detail>{`${selectedFilterAppValues}`}</Label.Detail>
                    <Icon name={'close'} style={{marginLeft: 5}} onClick={() => this.closeAppFilter()}/>
                </Label>
              </div>
          }
          {
            selectedFilterAppVersion && selectedFilterAppVersion !== 'all' &&
              <div key='appVersion'>
                <Label style={{ marginRight: 5 }} key='appVersion'>
                    <Icon name={iconSelector('appVersion')} />                      
                      {APP_VERSION_HEADER()}
                    <Label.Detail>{`${selectedFilterAppVersion}`}</Label.Detail>
                </Label>
              </div>
          }
        </>;
    };

    getDeviceImages = () => {
        getDataForCache("/api/devices/images", 1, "images")
            .then((res) => {
                this.setState({ deviceImages: res, loadingImages: false });
            })
            .catch((err) => {
                this.setState({ loadingImages: false });
                showError(
                    `${deviceListPageMessages().DEVICE_PICTURE_FETCH_ERROR} ${err.response?.data?.message ?? err.toString()}`
                );
            });
    };

    resetAllFilters = () => {
        this.setState({ isGenerateFilterBar: false }, () => {
            this.props.history.replace({ search: '' });
            localStorage.setItem("activeIndex", "-1");
            this.props.setDeviceList([]);
            this.props.setDeviceListFilter([]);
            this.setState(
                {
                    deviceListClone: [],
                    deviceListForTable: [],
                    selectedFilterValues: {},
                    searchKeyword: "",
                    selectedFilterAppValues: "",
                },
                () => {
                    window.sessionStorage.removeItem('deviceSearchUrl');
                    this.getDevices();
                }
            );
        });
    };

    handleChangeRadio = (view) => {
        this.setState({ toggleSwitch: view });
        this.props.startDeviceLoad("");
        setTimeout(() => {
            this.setState({ cardView: view });
            this.props.endDeviceLoad();
        }, 750);
    };

    onRememberFilterDevices = (value) => {
        this.setState({ searchKeyword: value }, () =>
            this.filterDevices(this.props.deviceListForFilter)
        );
    };

    saveSearchKeyword = searchKeyword => {
      const urlSearchObj = qs.parse(this.props.location.search);
      if (searchKeyword) {
        urlSearchObj.searchKeyword = searchKeyword;
      } else {
        delete urlSearchObj.searchKeyword;
      }
      this.props.history.replace({ search: qs.stringify(urlSearchObj) });
      sessionStorage.setItem('deviceSearchUrl','?'+ qs.stringify(urlSearchObj));
    }

    handleChangeSearch = value => {
        this.setState({ searchKeyword: value }, () => {
            this.onRememberFilterDevices(this.state.searchKeyword);
            this.saveSearchKeyword(this.state.searchKeyword);
        });
    };

    filterDevices = (data) => {
        if (this.state.searchKeyword) {
            let filtered = filterByValue(data, this.state.searchKeyword);
            this.props.setDeviceList(filtered)
            this.setState({deviceListForTable: filtered})
        } else {
            this.props.setDeviceList(this.props.deviceListForFilter)
            this.setState({deviceListForTable: this.props.deviceListForFilter})
        }
    };

    //for child component Start

    changeDeviceList = (deviceList, deviceListForFilter) =>{
        this.setState({deviceListForTable: deviceList})
        this.props.setDeviceList(deviceList)
        this.props.setDeviceListFilter(deviceListForFilter)
    }
    changeFilterValues = (selectedFilterValues) =>
        this.setState({ selectedFilterValues });

    changeFilterAppValues = (selectedFilterAppValues) =>
        this.setState({ selectedFilterAppValues });

    changeFilterAppVersion = selectedFilterAppVersion => this.setState({ selectedFilterAppVersion });

    onHideSideBar = () => this.setState({ isSidebarVisible: false });

    onShowSideBar = () => this.setState({ isSidebarVisible: true });

    handleOpenCancelReservationModal = () => this.setState({ isOpenCancelReservationModal: true });

    handleCloseCancelReservationModal = () => this.setState({ isOpenCancelReservationModal: false });

    setCancelledReservationId = (id) => this.setState({ cancelledReservationId: id });

    //for child component End

    onFavoriteDevice = async (device) => {
        let [deviceList, deviceListClone] = [
            JSON.parse(JSON.stringify(this.props.deviceList)),
            JSON.parse(JSON.stringify(this.state.deviceListClone)),
        ];
        const [objectIndex, objectIndexClone] = [
            deviceList.findIndex((obj) => obj.id === device.id),
            deviceListClone.findIndex((obj) => obj.id === device.id),
        ];
        try {
            if (device.favorite) {
                await deleteFavoriteDevice(device.id);
                showInfo(REMOVE_DEVICE_FROM_FAVORITE_MESSAGE(), 1500);
            } else {
                await createFavoriteDevice(device.id);
                showInfo(ADD_DEVICE_TO_FAVORITE_MESSAGE(), 1500);
            }
            deviceList[objectIndex].favorite = !device.favorite;
            deviceListClone[objectIndexClone].favorite = !device.favorite;
            this.setState({ deviceListClone }, () => this.changeDeviceList(deviceList, deviceListClone));
        } catch (error) {
            device.favorite
                ? showError(
                      deviceListPageMessages().DEVICE_NOT_SET_UNFAVORITE_MESSAGE+
                          error?.response?.message ?? error?.message?.toString()
                  )
                : showError(
                      deviceListPageMessages().DEVICE_NOT_SET_FAVORITE_MESSAGE +
                          error?.response?.message ?? error?.message?.toString()
                  );
        }
    };

    getReservableGroupList = async() =>{
        try {
          const res = await getReservableGroupList();
          this.setState({reservableGroupList:res.data})
        } catch (error) {
            showError(`${deviceListPageMessages().RESERVATION_GROUP_NOT_REACHED} ${error.response?.data?.message ?? error.toString()}`);
        }
      }


    getSystemParameters = async() => {
        try{
            const res = await getSystemParameters();
            this.setState({systemParameters:res.data})
        }catch(err){
            showError(`${NOT_FETCH_SYSTEM_PARAMETERS_ERROR_MESSAGE()} ${err?.response?.data?.message ?? err.toString()}`)
        }
    }

    checkVisibilityOfDevice = async device =>{
        if( !this.state.privileges?.includes(PrivilegeConstants.SUPREME_DEVICE_MANAGEMENT) && device?.users !== null && device?.status?.Manual === true){   
            const visibilityOfBusyDevice = this.state.systemParameters?.data?.
            find((systemParameter) =>systemParameter?.parameterKey === 'visibilityOfBusyDevice');
            
            if(visibilityOfBusyDevice?.parameterValue === 'no'){
                return false;
            }else if(visibilityOfBusyDevice?.parameterValue === 'group only' 
                    && device?.users?.every(user=> user?.activeGroupId != localStorage.getItem('activeGroup'))){
                return false;
            }
        }
        return true;
    }

    deviceListView = (deviceList) => {
        deviceList = orderDevices(deviceList);
        if (this.state.cardView) {
            return (
                <Suspense fallback={null}>
                    <DeviceListCard
                        deviceList={deviceList}
                        reloadImages={this.getDeviceImages}
                        reloadDevices = {this.getDevices}
                        deviceImages={this.state.deviceImages}
                        loadingImages={this.state.loadingImages}
                        privileges={this.state.privileges}
                        onFavoriteDevice={this.onFavoriteDevice}
                        toggleSelectedDevice={this.toggleSelectedDevice}
                        selectedDeviceIdList={this.state.selectedDeviceIdList}
                        userDetails={this.state.userDetails}
                        setCancelledReservationId={this.setCancelledReservationId}
                        handleOpenCancelReservationModal={this.handleOpenCancelReservationModal}
                        getReservableGroupList={this.getReservableGroupList}
                        reservableGroupList={this.state.reservableGroupList}
                    />
                </Suspense>
            );
        } else {
            return (
                <Suspense fallback={null}>
                    <DeviceListTable
                        deviceList={this.state.deviceListForTable}
                        privileges={this.state.privileges}
                        reloadImages={this.getDeviceImages}
                        reloadDevices = {this.getDevices}
                        loadingImages={this.state.loadingImages}
                        deviceImages={this.state.deviceImages}
                        onFavoriteDevice={this.onFavoriteDevice}
                        toggleSelectedDevice={this.toggleSelectedDevice}
                        selectedDeviceIdList={this.state.selectedDeviceIdList}
                        getReservableGroupList={this.getReservableGroupList}
                        reservableGroupList={this.state.reservableGroupList}
                    />
                </Suspense>
            );
        }
    };

    componentDidMount() {
        let userDetails = JSON.parse(Base64.decode(localStorage.getItem('userDetails')));
        const privileges = getPrivileges();
        this.getSystemParameters();
        this.setState({ userDetails, privileges }, () => {
            if (privileges.includes("4")) {
                this.getDevices();
                this.getDeviceImages();
            }
        });
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.deviceListForFilter !== this.props.deviceListForFilter){
            const urlSearchObj = qs.parse(this.props.location.search);
            if (urlSearchObj.searchKeyword) {
                this.onRememberFilterDevices(urlSearchObj.searchKeyword);
            }
        }
        if(prevState.deviceImages !== this.state.deviceImages && !this.state.loadingImages){
            this.getDeviceImages();
        }
    }


    render() {
        const { selectedFilterValues } = this.state;
        return (
            <>
                <DeviceSidebar
                    deviceList={this.state.deviceListClone}
                    onHideSidebar={this.onHideSideBar}
                    visible={this.state.isSidebarVisible}
                    // card view props start
                    reloadImages={this.getDeviceImages}
                    deviceImages={this.state.deviceImages}
                    loadingImages={this.state.loadingImages}
                    privileges={this.state.privileges}
                    onFavoriteDevice={this.onFavoriteDevice}
                    // card view props end
                />
                <div className='main-container'>       
                    <div className='main-left-container'>
                        <div className='main-left-container-fixed'>
                            <h1>{DEVICE_LIST_HEADER()}</h1>
                            <div className='main-left-content'>
                                <DebouncedInput
                                    onChangeProp={value => this.handleChangeSearch(value)}
                                    placeHolderProp={SEARCH_PLACEHOLDER()}
                                    iconProp={true}
                                    fluidProp={true}
                                    valueProp={this.state.searchKeyword}
                                    debounceProp={DEBOUNCE_WAIT.LONG}
                                />

                                {this.state.deviceListClone?.length > 0 &&
                                    this.state.isGenerateFilterBar && (
                                        <FilterBar //Filter for the left-handside
                                            deviceList={this.state.deviceListClone}
                                            changeDeviceList={this.changeDeviceList}
                                            changeFilterValues={
                                                this.changeFilterValues
                                            }
                                            changeFilterAppValues={
                                                this.changeFilterAppValues
                                            }
                                            onRememberFilterDevices={
                                                this.onRememberFilterDevices
                                            }
                                            deviceCount={
                                                this.props.deviceList.length
                                            }
                                            changeFilterAppVersion={this.changeFilterAppVersion}
                                        />
                                    )}
                            </div>
                        </div>
                    </div>
                    <div id="device-list-container" className="main-right-container">
                        <div className='device-card-viewbar'>
                            <div className='filters-right' style={{marginTop: '0.5rem'}}>
                            {this.state.selectedDeviceIdList.length > 0 && (
                                <Button
                                    style={{fontSize: '0.85714286rem', padding: '0.58em'}}
                                    position="right"
                                    onClick={this.connectToDevices}
                                >
                                    {deviceListPageMessages().CONNECT_TO_DEVICES_BUTTON}
                                </Button>
                            )}
                        
                                {this.showFilterValues()}
                            
                            
                            {(Object.keys(selectedFilterValues).some((key) => {
                                return selectedFilterValues[key].length > 0;
                            }) ||
                                this.state.selectedFilterAppValues !== "") && (
                                <div>
                                    <Label
                                        color={"red"}
                                        id='label-main-filter'
                                        as={"a"}
                                        onClick={this.resetAllFilters}
                                    >
                                        <Icon name={X_ICON} />
                                        {deviceListPageMessages().CLEAR_FILTERS_BUTTON}
                                    </Label>
                                </div>
                            )}
                            </div>

                            <div className='button-group-left'>
                            <Button.Group basic style={{marginRight: 10, marginLeft: 10}}>
                                    <Button icon active={this.state.toggleSwitch}
                                        onClick={() => this.handleChangeRadio(true)}>
                                    <Icon name={IMAGE_ICON}/>
                                    </Button>
                                    <Button icon active={!this.state.toggleSwitch}
                                        onClick={() => this.handleChangeRadio(false)}>
                                    <Icon name={TH_LIST_ICON}/>
                                    </Button>
                                </Button.Group>
                                <Button basic icon style={{marginLeft: 10}}
                                    onClick={() => this.onShowSideBar()}>
                                <Icon.Group>
                                    <Icon name={MOBILE_ALTERNATE_ICON} />
                                    <Icon corner='top right' name={STAR_ICON} />
                                </Icon.Group>
                                </Button>
                            </div>
                        </div>
                        
                        <div>
                            <Dimmer active={this.props.loading} inverted />
                            <Loader
                                active={this.props.loading}
                                inline={"centered"}
                                indeterminate
                            >
                                {" "}
                                {LOADING()}{" "}
                                {this.props.loadingContent}...{" "}
                            </Loader>

                            <div className="device-cards-container">
                                {this.deviceListView(this.props.deviceList)}
                            </div>
                        </div>
                        {(this.state.userDetails?.groups?.length > 0 &&
                            this.props.systemParameterList.length > 0 &&
                             this.props.systemParameterList.find(param => param.parameterKey === 'isChatAppEnabled')?.parameterValue ==='true') &&
                            <Chat
                                chatPortalVisibility={this.state.chatPortalVisibility}
                                setchatPortalVisibility={value=>this.setState({chatPortalVisibility:value})}
                                type={'group'}
                            />
                        }
                     </div>

                    <CancelReservationModal
                        open={this.state.isOpenCancelReservationModal}
                        onClose={this.handleCloseCancelReservationModal}
                        afterDeleteCallback={this.handleCloseCancelReservationModal}
                        id={this.state.cancelledReservationId}
                    />
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.deviceList.loading,
        loadingContent: state.deviceList.loadingContent,
        deviceList: state.deviceList.deviceList,
        deviceListForFilter: state.deviceList.deviceListFilter,
        systemParameterList : state.systemParameters.systemParameters
    };
};

export default withTranslation()(
    connect(mapStateToProps, { startDeviceLoad, endDeviceLoad, setDeviceList, setDeviceListFilter })(DeviceListPage)
);

